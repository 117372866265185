//-					BETTER COMMENTS COLOR GUIDE					//
//#		Data													//
//?		Section													//
//.		Section Component										//

//-					COMPONENT WIDGETS							//
//1		- Air Quality											//
//2		- Density												//
//3		- Lights												//
//4		- Thermostat											//

import * as React from "react";

// MUI
import {
	Divider,
	Grid,
	LinearProgress,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

// Lab
import { Skeleton } from "@material-ui/lab";

// Router
import { useHistory, useLocation, useParams } from "react-router";

// Redux
import { fetchBuilding } from "../../features/buildings/buildingsSlice";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";

// Interfaces
import { IDevice } from "../../interfaces";
import { RootState } from "../../app/store";

// DB
import { useFirebaseConnect } from "react-redux-firebase";

// Util
import { find, isEmpty, orderBy, uniq } from "lodash";

// Components
import { SpaceAppBarBottom } from "../../components/AppBars/SpaceAppBar/SpaceAppBarBottom";
import { GlassPaper } from "../../components/GlassPaper/GlassPaper";
import { GlassDial } from "../../components/GlassDial/GlassDial";
import { Weather } from "../../components/Weather/Weather";

// Widgets
import { DeviceLoader } from "../../components/DeviceWidgets/util/DeviceLoader/DeviceLoader";
import { IAQCard } from "../../components/DeviceWidgets/IAQ/IAQCard/IAQCard";
import { ThermostatCard } from "../../components/DeviceWidgets/Thermostat";
import { Density } from "../../components/DeviceWidgets/Density/Density";
import { LightingCard } from "../../components/DeviceWidgets/Lighting";

import { functions } from "../../firebase/firebase";
const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

interface ISpaceProps {}

export const Space: React.FC<ISpaceProps> = (props) => {
	const { buildingId, floorId, spaceId }: any = useParams();

	// HOOKS
	// Router
	const history = useHistory();
	const { pathname, search } = useLocation();

	// Styles
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	// Redux
	const dispatch = useDispatch();

	// STORE
	// Firebase
	const api = useAppSelector((state: RootState) => state.firebase.profile.api);
	const uid = useAppSelector((state: any) => state.firebase?.auth?.uid);
	useFirebaseConnect([`users/${uid}/control`]);

	// Building data
	const buildingStore = useAppSelector((state: RootState) => state.buildings); //buildings fetched and stored on selection
	const buildingData = buildingStore.data?.[buildingId];

	// If building data exists, get all associated data with spaces
	const building = buildingStore.data?.[buildingId] || {
		floors: [],
	};
	const floor = find(building.floors, ["id", floorId]) || {
		spaces: [],
	};
	const space = find(floor.spaces, ["id", spaceId]);

	// If no buildingData, load it into the store
	React.useEffect(() => {
		if (!Boolean(buildingData) && buildingStore.status !== "loading") {
			dispatch(fetchBuilding({ api, id: buildingId }));
		}
		// eslint-disable-next-line
	}, [buildingData]);

	//# 						DEVICES		 						//
	//1 AIR QUALITY 												//
	const airQualityDevices =
		space?.devices.filter((device: IDevice) => {
			return device.virtualProfile === "virtualAirQuality";
		}) || [];

	//2 DENSITY	 													//
	const densityDevices =
		space?.devices.filter((device: IDevice) => {
			return device.virtualProfile === "virtualDensityIo";
		}) || [];

	//3 LIGHTING 													//
	const lightingDevices =
		space?.devices.filter((device: IDevice) => {
			return device.virtualProfile === "virtualLightSwitch";
		}) || [];

	//4 THERMOSTATS 												//
	const thermostatDevices =
		space?.devices.filter((device: IDevice) => {
			return (
				device.virtualProfile === "virtualThermostat" ||
				device.virtualProfile === "virtualThermostatSingleSp"
			);
		}) || [];

	// Get unique device types to dynamically load icons on appbar
	const deviceTypes = uniq(
		space?.devices.map((device: any) => device.virtualProfile)
	);

	// Convenience ob to check for existence of device
	const hasDevice = {
		access: Boolean(deviceTypes.includes("virtualAccess")),
		blinds: Boolean(deviceTypes.includes("virtualBlinds")),
		density: Boolean(deviceTypes.includes("virtualDensityIo")),
		iaq: Boolean(deviceTypes.includes("virtualAirQuality")),
		lights: Boolean(deviceTypes.includes("virtualLightSwitch")),
		schedule: Boolean(deviceTypes.includes("virtualSchedule")),
		thermostat: Boolean(deviceTypes.includes("virtualThermostat")),
	};

	// If no devices are currently selected, default load one in order
	if (!search && Boolean(buildingData)) {
		if (hasDevice.iaq) {
			history.push({
				pathname: pathname,
				search: "?component=iaq",
			});
		} else if (hasDevice.thermostat) {
			history.push({
				pathname: pathname,
				search: "?component=thermostat",
			});
		} else if (hasDevice.lights) {
			history.push({
				pathname: pathname,
				search: "?component=lights",
			});
		} else if (hasDevice.density) {
			history.push({
				pathname: pathname,
				search: "?component=density",
			});
		}
	}

	// Convenience ob to check selected component
	const selection = {
		access: Boolean(search.includes("access")),
		blinds: Boolean(search.includes("blinds")),
		density: Boolean(search.includes("density")),
		iaq: Boolean(search.includes("iaq")),
		lights: Boolean(search.includes("lights")),
		schedule: Boolean(search.includes("schedule")),
		thermostat: Boolean(search.includes("thermostat")),
	};

	// If there's only 1 device, don't show metadata averages
	const singleDevice = Boolean(space?.devices?.length === 1);

	// ! DEP: PAGE REFRESH TO TAKE CARE OF OVERFLOW
	React.useEffect(() => {
		const interval = setInterval(() => {
			dispatch(fetchBuilding({ api, id: buildingId }));
		}, 20000);

		const hardRefresh = setInterval(() => {
			window.location.reload();
		}, 86400000);

		return () => {
			clearInterval(interval);
			clearInterval(hardRefresh);
		};
	}, []);
	// ! DEP: PAGE REFRESH TO TAKE CARE OF OVERFLOW

	// dispatch(fetchBuilding({ api, id: buildingId }));

	const isLoading = Boolean(
		!space ||
			(buildingStore.status === "loading" &&
				(!space?.metadata?.airQuality?.avg ||
					!space?.metadata?.temperature?.avg))
	);

	const clampedAverage =
		space?.metadata?.airQuality?.avg >= 100
			? 100
			: !isNaN(Number(space?.metadata?.airQuality?.avg))
			? Math.round(Number(space?.metadata?.airQuality.avg))
			: 0;

	return (
		<span style={{ display: "flex", paddingBottom: "200px" }}>
			{isLoading ? (
				<LinearProgress style={{ width: "100%" }} />
			) : (
				<div
					style={{
						display: "flex",
						flexWrap: "wrap",
						padding: 0,
						width: "100%",
					}}
				>
					{/* 
					//? 						Metadata Card or Widget | Weather							/}
					//? 	- If >1 device, show Metadata Card		 										/}
					//? 	- If 1 device, show Widget for given selection									/}
					*/}

					<div
						style={{
							display: "flex",
							width: "100%",
							justifyContent: "space-evenly",
						}}
					>
						<GlassPaper square style={{ width: "100%" }}>
							<Grid container>
								{/* Header */}
								<Grid item xs={12}>
									<Typography
										variant="h5"
										align="center"
										style={{ padding: "1rem", fontWeight: 700 }}
									>
										{space.name}
									</Typography>
								</Grid>

								<Divider style={{ width: "100%" }} />

								{/* 
								//* 					METADATA CARD / WIDGET				//
								 */}
								<Grid item xs={12} sm={7}>
									{!singleDevice || singleDevice ? (
										//* 					METADATA CARD									//
										//*			- Show device metadata if there are multiple devices		//
										<div
											style={{
												display: "flex",
												flexWrap: "wrap",
												justifyContent: "space-evenly",
												alignItems: "center",
												height: "100%",
												padding: "1rem 0",
											}}
										>
											{
												Boolean(
													//* Air Quality Metadata Dials //
													space.metadata?.airQuality &&
														!isEmpty(space.metadata?.airQuality)
												) && (
													<GlassDial
														id={"aq"}
														value={clampedAverage}
														label={`Air Quality`}
														size={150}
														colorByValue
														mobile={mobile}
													/>
												)
												// : (
												// 	<GlassDial
												// 		id={"no_airquality"}
												// 		value={"N/A"}
												// 		label={`No IAQ Metadata`}
												// 		size={150}
												// 		color={grey[500]}
												// 		mobile={mobile}
												// 	/>
												// )
											}
											{
												Boolean(
													//* Thermostat Metadata Dials //
													space.metadata?.temperature &&
														!isEmpty(space.metadata?.temperature)
												) && (
													<GlassDial
														id={"temp"}
														value={Number(space.metadata?.temperature.avg)}
														label={`Temperature`}
														size={150}
														appendToValue="°"
														mobile={mobile}
													/>
												)
												// : (
												// 	<GlassDial
												// 		id={"no_temp"}
												// 		value={"N/A"}
												// 		label={`No Temp Metadata`}
												// 		size={150}
												// 		color={grey[500]}
												// 		mobile={mobile}
												// 	/>
												// )
											}
										</div>
									) : (
										//* 					WIDGET														//
										//*			- Show device widget isntead of metadata if there is only 1 device		//
										<>
											{/* 
											//1 					Air Quality					 //
											*/}
											{Boolean(selection.iaq) &&
												airQualityDevices.map((iaq: any) => (
													<Grid item xs={12} key={iaq.id}>
														<DeviceLoader
															device={iaq}
															buildingId={building.id}
															floorId={floor.id}
															spaceId={space.id}
														>
															<IAQCard iaqDevice={iaq} />
														</DeviceLoader>
													</Grid>
												))}

											{/* 
											//2 					Density					 //
											*/}
											{densityDevices.map((density: any) => (
												<Grid item xs={12} key={density.id}>
													<DeviceLoader
														device={density}
														buildingId={building.id}
														floorId={floor.id}
														spaceId={space.id}
													>
														<Density densityDevice={density} />
													</DeviceLoader>
												</Grid>
											))}

											{/* 
											//3 					Lights					 //
											*/}
											{lightingDevices.map((light: any) => (
												<Grid item xs={12} key={light.id}>
													<DeviceLoader
														device={light}
														buildingId={building.id}
														floorId={floor.id}
														spaceId={space.id}
													>
														<LightingCard lightingDevice={light} />
													</DeviceLoader>
												</Grid>
											))}

											{/* 
											//4 					Thermostats				 //
											*/}
											{thermostatDevices.map((thermostat: any) => (
												<Grid item xs={12} key={thermostat.id}>
													<DeviceLoader
														device={thermostat}
														buildingId={building.id}
														floorId={floor.id}
														spaceId={space.id}
													>
														<ThermostatCard thermostatDevice={thermostat} />
													</DeviceLoader>
												</Grid>
											))}
										</>
									)}
								</Grid>

								{/* 
								//* 					WEATHER								//
								*/}
								<Grid item xs={12} sm={5}>
									{Boolean(buildingData) && (
										<Weather
											square
											backgroundImage={false}
											address={`${buildingData.address}, ${buildingData.city}, ${buildingData.state}, ${buildingData.zip}`}
											city={buildingData.city}
											id={buildingData.id}
										/>
									)}
								</Grid>
							</Grid>
						</GlassPaper>
					</div>

					<Divider style={{ width: "100%" }} />

					{/* 
					//? 						Device Widgets														/}
					//? 	- If >1 device, map out components selected by type	(selection variable above)			/}
					//? 		[Types]: density, iaq, lights, thermostat				 							/}
					*/}

					<Grid container>
						{/*	
							//1 							Air Quality									// 
							*/}
						{Boolean(selection.iaq) &&
							orderBy(airQualityDevices, ["name"]).map((iaq: any) => (
								<Grid item xs={12} sm={6} key={iaq.id}>
									<DeviceLoader
										device={iaq}
										buildingId={building.id}
										floorId={floor.id}
										spaceId={space.id}
									>
										<IAQCard iaqDevice={iaq} style={{ height: "100%" }} />
									</DeviceLoader>
								</Grid>
							))}

						{/*	
							//2 							Density									// 
							*/}
						{Boolean(selection.density) &&
							orderBy(densityDevices, ["name"]).map((density: any) => (
								<Grid item xs={12} sm={6} key={density.id}>
									<DeviceLoader
										device={density}
										buildingId={building.id}
										floorId={floor.id}
										spaceId={space.id}
									>
										<Density densityDevice={density} />
									</DeviceLoader>
								</Grid>
							))}

						{/*	
							//3 							Lights									// 
							*/}
						{Boolean(selection.lights) &&
							orderBy(lightingDevices, ["name"]).map((light: any) => (
								<Grid item xs={12} sm={6} key={light.id}>
									<DeviceLoader
										device={light}
										buildingId={building.id}
										floorId={floor.id}
										spaceId={space.id}
									>
										<LightingCard lightingDevice={light} />
									</DeviceLoader>
								</Grid>
							))}

						{/*	
							//4 							Thermostat								// 
							*/}
						{Boolean(selection.thermostat) &&
							orderBy(thermostatDevices, ["name"]).map((thermostat: any) => (
								<Grid item xs={12} sm={6} key={thermostat.id}>
									<DeviceLoader
										device={thermostat}
										buildingId={building.id}
										floorId={floor.id}
										spaceId={space.id}
									>
										<ThermostatCard thermostatDevice={thermostat} />
									</DeviceLoader>
								</Grid>
							))}
					</Grid>
				</div>
			)}

			{/* 
			//? 						Device Selection Appbar												/}
			//? 	- If device exists, tell the AppBar to display the appropriate icon						/}
			//? 		[Types]: blinds, density, iaq, lights, thermostat				 					/}
			*/}
			{isLoading ? (
				<Skeleton
					height={120}
					width={"100%"}
					style={{ position: "absolute", bottom: 70, left: 0 }}
				/>
			) : (
				<SpaceAppBarBottom
					space={space}
					blinds={hasDevice.blinds}
					density={hasDevice.density}
					iaq={hasDevice.iaq}
					lights={hasDevice.lights}
					thermostat={hasDevice.thermostat}
				/>
			)}
		</span>
	);
};
